import React from 'react';
import { Button, Container, Icon, Text, VStack, Link } from '@chakra-ui/react';
import { faLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ExtensionLoginButton,
  WebWalletLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton
} from '@multiversx/sdk-dapp/UI';
import { Link as RouterLink } from 'react-router-dom';
import { routeNames } from 'routes';
function UnlockCard() {
  return (
    <Container
      maxW={'full'}
      fontSize={'22px'}
      py={3}
      color={'brand.orange'}
      borderRadius={20}
    >
      <VStack spacing={8}>
        <Text
          fontSize={'20px'}
          fontFamily={'Fredoka'}
          fontWeight={600}
          color={'brand.black'}
          marginBottom={'0 !important'}
          align={'center'}
        >
          Connect with your MultiversX wallet
        </Text>
        <Button
          as={ExtensionLoginButton}
          callbackRoute={routeNames.home}
          w={'40'}
          color={'brand.yellow'}
          background={'brand.orange'}
          fontSize={'22px'}
          fontWeight={'normal'}
        >
          Extension
        </Button>
        <Button
          as={WebWalletLoginButton}
          callbackRoute={routeNames.home}
          w={'40'}
          color={'brand.yellow'}
          background={'brand.orange'}
          fontSize={'22px'}
          fontWeight={'normal'}
        >
          Web wallet
        </Button>
        <Button
          as={LedgerLoginButton}
          callbackRoute={routeNames.home}
          w={'40'}
          color={'brand.yellow'}
          background={'brand.orange'}
          fontSize={'22px'}
          fontWeight={'normal'}
        >
          Ledger
        </Button>
        <Button
          as={WalletConnectLoginButton}
          callbackRoute={routeNames.home}
          isWalletConnectV2={true}
          w={'40'}
          color={'brand.yellow'}
          background={'brand.orange'}
          fontSize={'22px'}
          fontWeight={'normal'}
        >
          xPortal
        </Button>
        <Link
          as={RouterLink}
          to={routeNames.home}
          _hover={{ color: 'brand.orangehover' }}
        >
          <Icon as={FontAwesomeIcon} icon={faLeftLong}></Icon>
        </Link>
      </VStack>
    </Container>
  );
}

export default UnlockCard;
