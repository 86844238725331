import { UnlockPage } from '@multiversx/sdk-dapp/UI/pages';
import { dAppName } from 'config';
import Gallery from 'pages/Gallery';
import withPageTitle from './components/PageTitle';
import AdminPanel from './pages/AdminPanel/index';

export const routeNames = {
  home: '/',
  unlock: '/unlock',
  admin: '/admin'
};

const routes: Array<any> = [
  {
    path: routeNames.home,
    title: 'Home',
    component: Gallery
  },
  {
    path: routeNames.admin,
    title: 'AdminDashboard',
    component: AdminPanel
  },
  {
    path: routeNames.unlock,
    title: 'Connect',
    component: UnlockPage
  }
];

const mappedRoutes = routes.map((route) => {
  const title = route.title ? `${dAppName}` : `${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});

export default mappedRoutes;
