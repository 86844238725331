import React from 'react';
// import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons';
// import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
// import { logout } from '@multiversx/sdk-dapp/utils';
import { Link as RouterLink } from 'react-router-dom';
import styles from './header.module.scss';
import NavMenu from './NavMenu';
import discordBrownLogo from '../../../assets/images/discord-brown.svg';
import discordLogo from '../../../assets/images/discord.svg';
import LogoSrc from '../../../assets/images/logo.svg';
import twitterBrownLogo from '../../../assets/images/twitter-brown.svg';
import twitterLogo from '../../../assets/images/twitter.svg';
const Navbar = ({ inverted = false }) => {
  const { header, logo } = styles;

  const links = [
    { title: 'Home', route: 'https://www.cowcow.io/' },
    { title: 'The City', route: 'https://www.cowcow.io/thecity' },
    { title: 'Urban Plan', route: 'https://www.cowcow.io/urban-plan' },
    { title: 'Staking', route: '/' },
    {
      title: 'More',
      route: '',
      submenu: [
        { title: '#wecowcow', route: 'https://www.cowcow.io/thebrand' },
        { title: 'Manifesto', route: 'https://www.cowcow.io/manifesto' },
        { title: 'Team', route: 'https://www.cowcow.io/team' },
        { title: 'Gallery', route: 'https://www.cowcow.io/gallery' }
      ]
    }
  ];

  const socials = [
    {
      icon: discordLogo,
      iconMobile: discordBrownLogo,
      route: 'https://discord.gg/cowcow'
    },
    {
      icon: twitterLogo,
      iconMobile: twitterBrownLogo,
      route: 'https://twitter.com/wecowcow'
    }
  ];

  return (
    <header className={header}>
      <div>
        <RouterLink to={'https://cowcow.io'}>
          {<img src={LogoSrc} alt={'cowcow'} className={logo} />}
        </RouterLink>
      </div>
      <NavMenu links={links} socials={socials} inverted={inverted} />
    </header>
  );
};

export default Navbar;
