import React from 'react';
import { Flex, VStack } from '@chakra-ui/react';
import { AuthenticatedRoutesWrapper } from '@multiversx/sdk-dapp/wrappers';
import { useLocation } from 'react-router-dom';
import routes, { routeNames } from 'routes';
import Footer from './Footer';
import Navbar from './Navbar';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { search } = useLocation();
  return (
    <VStack className='wrapper'>
      <Navbar />
      <Flex
        as='main'
        width={'90%'}
        marginTop={'4rem !important'}
        grow={1}
        direction={'column'}
        justifyContent={'start'}
        alignItems={'center'}
      >
        <AuthenticatedRoutesWrapper
          routes={routes}
          unlockRoute={`${routeNames.unlock}${search}`}
        >
          {children}
        </AuthenticatedRoutesWrapper>
      </Flex>
      <Footer />
    </VStack>
  );
};

export default Layout;
