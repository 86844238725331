import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  HStack,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useBreakpointValue
} from '@chakra-ui/react';
import {
  AbiRegistry,
  SmartContract,
  Address,
  ResultsParser,
  BigUIntValue,
  VariadicValue,
  Transaction,
  TransactionPayload,
  ContractCallPayloadBuilder,
  ContractFunction,
  AddressValue,
  U64Value,
  TokenIdentifierValue,
  StringValue,
  Query
} from '@multiversx/sdk-core/out';
import {
  useGetAccountInfo,
  useGetPendingTransactions
} from '@multiversx/sdk-dapp/hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import {
  ApiNetworkProvider,
  ProxyNetworkProvider
} from '@multiversx/sdk-network-providers';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import { Link as RouterLink } from 'react-router-dom';
import {
  api,
  gateway,
  nftIdentifier,
  pageSize,
  stakingContractAddress,
  tokenIdentifier
} from 'config';
import { routeNames } from 'routes';
import { buildNftPayLoadFromString } from 'utils';
import CowMap from '../../assets/cow_map.json';
import NonceRanks from '../../assets/cowcow_nonce_rarity.json';
import { ReactComponent as Checkmark } from '../../assets/images/check.svg';
import Logo from '../../assets/images/logo.svg';
import MooveLogo from '../../assets/images/MF.png';
import jsonData from '../../assets/varaprstake.abi.json';

const hexZero = (nonce: string) => {
  let hexnonce = parseInt(nonce).toString(16);
  if (hexnonce.length % 2 !== 0) {
    hexnonce = '0' + hexnonce;
  }
  return hexnonce;
};

const Gallery = () => {
  const { address } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);
  const { hasPendingTransactions } = useGetPendingTransactions();

  const json = JSON.parse(JSON.stringify(jsonData));
  const abiRegistry = AbiRegistry.create(json);
  const contract = new SmartContract({
    address: new Address(stakingContractAddress),
    abi: abiRegistry
  });
  const networkProvider = new ProxyNetworkProvider(gateway);
  const [walletNfts, setWalletNfts] = useState<any[]>([]);
  const [shownWalletNfts, setShownWalletNfts] = useState<any[]>([]);
  const [selectedWalletNfts, setSelectedWalletNfts] = useState<string[]>([]);
  const [shownStakedNfts, setShownStakedNfts] = useState<number[]>([]);
  const [selectedStakedNfts, setSelectedStakedNfts] = useState<number[]>([]);
  const [shownClaimNfts, setShownClaimNfts] = useState<any[]>([]);
  const [selectedClaimNfts, setSelectedClaimNfts] = useState<string[]>([]);
  const [totalCows, setTotalCows] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [markedCows, setMarkedCows] = useState<any[]>([]);
  const [contractData, setContractData] = useState<any>({
    min_reward_amount: new BigNumber(70000000000000),
    min_stake_amount: new BigNumber(1),
    one_share: new BigNumber(1),
    reward_per_block: new BigNumber(7000000000000),
    rewards_accumulated_for_user: [
      {
        token_identifier: '',
        token_nonce: new BigNumber(0),
        amount: new BigNumber(0)
      }
    ],
    shares: new BigNumber(0),
    staked_nfts: [],
    total_value_locked: new BigNumber(0),
    unlock_period: new BigNumber(864000),
    unstaked_nfts: []
  });
  const [showSelectAllText, setShowSelectAllText] = useState<boolean>(true);
  const [totalSupply, setTotalSupply] = useState<number>(0);
  const [circulatingSupply, setCirculatingSupply] = useState<number>(0);

  const selectAllWallet = () => {
    if (selectedWalletNfts.length !== shownWalletNfts.length) {
      const nftIds: string[] = [];
      for (const index in shownWalletNfts) {
        nftIds.push(shownWalletNfts[index]['identifier']);
      }
      setSelectedWalletNfts(nftIds);
    } else {
      setSelectedWalletNfts([]);
    }
  };

  const selectAllStaked = () => {
    if (selectedStakedNfts.length !== shownStakedNfts.length) {
      const nftIds: number[] = [];
      for (const index in shownStakedNfts) {
        nftIds.push(shownStakedNfts[index]);
      }
      setSelectedStakedNfts(nftIds);
    } else {
      setSelectedStakedNfts([]);
    }
  };

  const selectAllUnbonded = () => {
    const claimNfts = shownClaimNfts;
    // .filter(
    //   (nft) => new Date() > new Date(nft['unlock_time'] * 1000)
    // );
    if (selectedClaimNfts.length !== claimNfts.length) {
      const nftIds: string[] = [];
      for (const index in claimNfts) {
        nftIds.push(claimNfts[index]['nonce']);
      }
      setSelectedClaimNfts(nftIds);
    } else {
      setSelectedClaimNfts([]);
    }
  };

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  useEffect(() => {
    if (isLoggedIn) {
      axios
        .get(
          `${api}/accounts/${address}/nfts?search=${nftIdentifier}&size=10000`
        )
        .then((res) => {
          setWalletNfts(res.data);
          setSelectedWalletNfts([]);
          setShownWalletNfts(res.data.slice(0, pageSize));
        });
    }
  }, [hasPendingTransactions]);

  useEffect(() => {
    if (!isLoggedIn) {
      axios
        .get(
          `${api}/accounts/${stakingContractAddress}/nfts/count?search=${nftIdentifier}`
        )
        .then((res) => {
          setTotalCows(res.data);
        });
    }
  }, [hasPendingTransactions]);

  useEffect(() => {
    let totalSupplyTmp = 0;
    let circulatingSupplyTmp = 0;
    if (!isLoggedIn) {
      axios
        .get(`${api}/tokens/${tokenIdentifier}`)
        .then((res) => {
          totalSupplyTmp += Number(res.data.supply);
          circulatingSupplyTmp += Number(res.data.circulatingSupply);
        })
        .then(() => {
          const interaction = contract.methods.getTotalRewardsToCollect([]);
          const query = interaction.buildQuery();

          networkProvider.queryContract(query).then((res) => {
            const endpointDefinition = interaction.getEndpoint();
            const { firstValue, returnCode } =
              new ResultsParser().parseQueryResponse(res, endpointDefinition);
            if (returnCode.isSuccess()) {
              const firstValueAsStruct = firstValue?.valueOf();
              circulatingSupplyTmp += Math.round(
                firstValueAsStruct.toNumber() / 10 ** 18
              );
              setTotalSupply(totalSupplyTmp);
              setCirculatingSupply(circulatingSupplyTmp);
            }
          });
        });
    }
  }, [hasPendingTransactions]);

  useEffect(() => {
    const interaction = contract.methods.getAllDataForUser([]);
    let query;
    if (isLoggedIn) {
      query = interaction.withQuerent(new Address(address)).buildQuery();
    } else {
      query = interaction.buildQuery();
    }

    networkProvider.queryContract(query).then((res) => {
      const endpointDefinition = interaction.getEndpoint();
      const { firstValue, secondValue, returnCode } =
        new ResultsParser().parseQueryResponse(res, endpointDefinition);
      if (returnCode.isSuccess()) {
        const firstValueAsStruct = firstValue?.valueOf();
        setContractData(firstValueAsStruct);
        setShownStakedNfts(firstValueAsStruct.staked_nfts.slice(0, pageSize));
        setSelectedStakedNfts([]);
        setShownClaimNfts(firstValueAsStruct.unstaked_nfts.slice(0, pageSize));
        setSelectedClaimNfts([]);
        console.log(firstValueAsStruct);
      }
    });
  }, [hasPendingTransactions]);

  useEffect(() => {
    async function markEffect() {
      const all_nfts = [];
      if (walletNfts.length > 0) {
        all_nfts.push(...walletNfts.map((nft) => nft.nonce));
      }
      if (contractData.staked_nfts.length > 0) {
        all_nfts.push(
          ...contractData.staked_nfts.map((nft: any) => nft.toNumber())
        );
      }
      if (contractData.unstaked_nfts.length > 0) {
        all_nfts.push(
          ...contractData.unstaked_nfts.map((nft: any) => nft.nonce.toNumber())
        );
      }

      if (all_nfts.length > 0) {
        const markedCowsTmp = await getMarkedCows(all_nfts);
        setMarkedCows(markedCowsTmp);
      }
    }
    markEffect();
  }, [
    hasPendingTransactions,
    contractData.staked_nfts,
    contractData.unstaked_nfts,
    walletNfts
  ]);

  const getMarkedCows = async (nonces: number[]) => {
    const interaction = contract.methods.getMarkedNfts(nonces);
    const query = interaction.buildQuery();
    const networkProvider = new ApiNetworkProvider(api);

    const result = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, secondValue, returnCode } =
      new ResultsParser().parseQueryResponse(result, endpointDefinition);
    if (returnCode.isSuccess()) {
      const firstValueAsStruct = firstValue?.valueOf();
      return firstValueAsStruct.map((nft: any) => {
        return nft.toNumber();
      });
    } else {
      throw new Error('Error fetching data');
    }
  };

  useEffect(() => {
    if (!isLoggedIn) {
      const interaction = contract.methods.getTotalRewardsToCollect([]);
      const query = interaction.buildQuery();

      networkProvider.queryContract(query).then((res) => {
        const endpointDefinition = interaction.getEndpoint();
        const { firstValue, secondValue, returnCode } =
          new ResultsParser().parseQueryResponse(res, endpointDefinition);
        if (returnCode.isSuccess()) {
          const firstValueAsStruct = firstValue?.valueOf();
        }
      });
    }
  }, [hasPendingTransactions]);

  const sendStakeTransaction = async () => {
    if (selectedWalletNfts.length > 0) {
      let hexamount = selectedWalletNfts.length.toString(16);
      if (hexamount.length % 2 !== 0) {
        hexamount = '0' + hexamount;
      }
      let data =
        'MultiESDTNFTTransfer' +
        '@' +
        new Address(stakingContractAddress).hex() +
        '@' +
        hexamount;
      selectedWalletNfts.forEach((nft) => {
        data += buildNftPayLoadFromString(nft);
      });
      data += '@' + Buffer.from('stake').toString('hex');
      const swapTransaction = new Transaction({
        value: 0,
        data: new TransactionPayload(data),
        receiver: new Address(address),
        sender: new Address(address),
        gasLimit: Math.min(
          15000000 * (selectedWalletNfts.length + 4),
          600000000
        ),
        chainID: '1'
      });
      await refreshAccount();
      await sendTransactions({
        transactions: swapTransaction,
        transactionsDisplayInfo: {
          processingMessage: 'Staking Cows...',
          errorMessage: 'Error occured',
          successMessage: 'Cows staked'
        },
        redirectAfterSign: false
      });
    }
  };

  const sendUnstakeTransaction = async () => {
    const data = new ContractCallPayloadBuilder().setFunction(
      new ContractFunction('unstake')
    );
    for (const nonce of selectedStakedNfts) {
      data.addArg(new U64Value(nonce));
    }
    const unstakeTx = new Transaction({
      value: 0,
      data: data.build(),
      receiver: new Address(stakingContractAddress),
      sender: new Address(address),
      gasLimit: Math.min(15000000 * (selectedStakedNfts.length + 3), 600000000),
      chainID: '1'
    });
    await refreshAccount();
    await sendTransactions({
      transactions: unstakeTx,
      transactionsDisplayInfo: {
        processingMessage: 'Unbonding Cows...',
        errorMessage: 'Error occured',
        successMessage: 'Cows unbonded'
      },
      redirectAfterSign: false
    });
  };

  const sendRestakeTransaction = async () => {
    const data = new ContractCallPayloadBuilder().setFunction(
      new ContractFunction('restake')
    );
    for (const nonce of selectedClaimNfts) {
      data.addArg(new U64Value(nonce));
    }
    const restakeTx = new Transaction({
      value: 0,
      data: data.build(),
      receiver: new Address(stakingContractAddress),
      sender: new Address(address),
      gasLimit: Math.min(15000000 * (selectedClaimNfts.length + 3), 600000000),
      chainID: '1'
    });
    await refreshAccount();
    await sendTransactions({
      transactions: restakeTx,
      transactionsDisplayInfo: {
        processingMessage: 'Restaking Cows...',
        errorMessage: 'Error occured',
        successMessage: 'Cows rebonded'
      },
      redirectAfterSign: false
    });
  };

  const sendClaimTransaction = async () => {
    const data = new ContractCallPayloadBuilder().setFunction(
      new ContractFunction('claim')
    );
    for (const nonce of selectedClaimNfts) {
      data.addArg(new U64Value(nonce));
    }
    const claimTx = new Transaction({
      value: 0,
      data: data.build(),
      receiver: new Address(stakingContractAddress),
      sender: new Address(address),
      gasLimit: Math.min(15000000 * (selectedClaimNfts.length + 2), 600000000),
      chainID: '1'
    });
    await refreshAccount();
    await sendTransactions({
      transactions: claimTx,
      transactionsDisplayInfo: {
        processingMessage: 'Unbonding Cows...',
        errorMessage: 'Error occured',
        successMessage: 'Cows unbonded'
      },
      redirectAfterSign: false
    });
  };

  const sendClaimRewardsTransaction = async () => {
    const data = new ContractCallPayloadBuilder().setFunction(
      new ContractFunction('claimRewards')
    );
    const claimTx = new Transaction({
      value: 0,
      data: data.build(),
      receiver: new Address(stakingContractAddress),
      sender: new Address(address),
      gasLimit: 30000000,
      chainID: '1'
    });
    await refreshAccount();
    await sendTransactions({
      transactions: claimTx,
      transactionsDisplayInfo: {
        processingMessage: 'Claiming rewards...',
        errorMessage: 'Error occured',
        successMessage: 'Rewards claimed!'
      },
      redirectAfterSign: false
    });
  };

  useEffect(() => {
    const ss = showSelectAll();
    setShowSelectAllText(ss);
  }, [selectedTab, selectedWalletNfts, selectedStakedNfts, selectedClaimNfts]);

  const showSelectAll = () => {
    if (selectedTab === 0) {
      return (
        selectedWalletNfts.length === shownWalletNfts.length &&
        shownWalletNfts.length > 0
      );
    } else {
      if (selectedTab === 1) {
        return (
          selectedStakedNfts.length === shownStakedNfts.length &&
          shownStakedNfts.length > 0
        );
      } else {
        if (selectedTab === 2) {
          const claimNfts = shownClaimNfts.filter(
            (nft) => new Date() > new Date(nft['unlock_time'] * 1000)
          );
          return selectedClaimNfts.length === claimNfts.length;
        }
      }
    }
    return true;
  };

  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    const day = date.getDate();
    const suffix = ['st', 'nd', 'rd'][(day % 10) - 1] || 'th';

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    const month = monthNames[date.getMonth()];

    // get the year as a two digit number (1979 becomes 79)
    const year = date.getFullYear() % 100;

    // get time as hh:mm in 24h format
    const time = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });

    return `${day}${suffix} ${month} '${year}, ${time}`;
  };

  const shouldClaimButtonBeDisabled = () => {
    const claimNfts = shownClaimNfts.filter((nft) =>
      selectedClaimNfts.includes(nft.nonce)
    );
    const claimableNfts = claimNfts.filter(
      (nft) => new Date() > new Date(nft['unlock_time'] * 1000)
    );
    console.log(claimNfts, claimableNfts);
    return claimNfts.length !== claimableNfts.length;
  };

  return (
    <Container
      mx={isDesktop ? '96px' : '12px'}
      p={0}
      minW={isLoggedIn ? '56%' : '100%'}
      centerContent
    >
      {isLoggedIn && (
        <VStack w='full'>
          <HStack justifyContent={'space-between'} w='full'>
            <Box>
              <Text
                color={'brand.orange'}
                fontSize={isDesktop ? '78px' : '48px'}
                lineHeight={'5rem'}
                margin={0}
              >
                Staking
              </Text>
              <Text
                color={'brand.black'}
                fontSize={isDesktop ? '20px' : '16px'}
                fontFamily={'Fredoka'}
                fontWeight={600}
                margin={0}
                maxW={isDesktop ? 'unset' : '128px'}
              >
                We moove forward together.
              </Text>
            </Box>
            <VStack>
              <Text
                color={'brand.orange'}
                fontSize={isDesktop ? '32px' : '38px'}
                margin={0}
                paddingBottom={isDesktop ? 'unset' : '4px'}
                align={'center'}
              >
                Cows Staked
              </Text>
              <Text
                color={'brand.black'}
                fontSize={isDesktop ? '22px' : '26px'}
                fontFamily={'Fredoka'}
                fontWeight={600}
                margin={0}
                align={'center'}
              >
                {contractData['staked_nfts'].length}
              </Text>
            </VStack>
          </HStack>
          <HStack
            justifyContent={'space-between'}
            w='full'
            marginTop={'2.4rem !important'}
          >
            <Box>
              <Text
                color={'brand.yellow'}
                backgroundColor={'brand.orange'}
                fontSize={'22px'}
                lineHeight={'31px'}
                margin={'0 !important'}
                padding={'2px 8px'}
                borderRadius={'6px'}
              >
                1 day ={' '}
                {Math.round(
                  (contractData['reward_per_block'] / 10 ** 18) *
                    14400 *
                    (contractData['staked_nfts'].length +
                      contractData['staked_nfts'].filter((cow: BigNumber) => {
                        return markedCows.includes(cow.toNumber());
                      }).length *
                        2) *
                    100
                ) / 100}{' '}
                $MOOVE
              </Text>
            </Box>
            <Button
              className='navbar-button'
              color={'brand.yellow'}
              backgroundColor={'brand.orange'}
              fontSize={'22px'}
              margin={'0 !important'}
              padding={'17.5px 8px'}
              borderRadius={'6px'}
              onClick={sendClaimRewardsTransaction}
              isDisabled={
                contractData['rewards_accumulated_for_user'].length <= 0 ||
                contractData['rewards_accumulated_for_user'][
                  contractData['rewards_accumulated_for_user'].length - 1
                ]['amount'].toNumber() <
                  contractData['min_reward_amount'].toNumber()
              }
            >
              CLAIM{' '}
              {contractData['rewards_accumulated_for_user'].length > 0
                ? Math.round(
                    (contractData['rewards_accumulated_for_user'][
                      contractData['rewards_accumulated_for_user'].length - 1
                    ]['amount'] /
                      10 ** 18) *
                      10000
                  ) / 10000
                : 0}{' '}
              $MOOVE
            </Button>
          </HStack>
          <Box
            w={'full'}
            borderBottom={'3px solid'}
            borderColor={'brand.orange'}
            mx={'0px !important'}
            my={'20px !important'}
          />
          <Stack
            direction={isDesktop ? 'row' : 'column-reverse'}
            justifyContent={isDesktop ? 'space-between' : 'center'}
            align={isDesktop ? 'start' : 'center'}
            w='full'
            gap={4}
          >
            <Tabs
              w={'full'}
              variant={'solid-rounded'}
              position={'relative'}
              onChange={(index) => {
                setSelectedTab(index);
              }}
            >
              <Button
                position={'absolute'}
                top={'-2.4px'}
                right={'0px'}
                className='navbar-button'
                color={'brand.yellow'}
                backgroundColor={'brand.orange'}
                fontSize={isDesktop ? '22px' : '22px'}
                margin={'0 !important'}
                padding={'17.5px 8px'}
                borderRadius={'6px'}
                w={isDesktop ? '112px' : '98px'}
                onClick={() => {
                  if (selectedTab === 0) {
                    selectAllWallet();
                  }
                  if (selectedTab === 1) {
                    selectAllStaked();
                  }
                  if (selectedTab === 2) {
                    selectAllUnbonded();
                  }
                }}
              >
                {showSelectAllText ? 'Unselect All' : 'Select All'}
              </Button>

              <TabList
                background={'brand.orange'}
                color={'brand.yellow'}
                borderRadius={'9px'}
                border={'3px solid'}
                borderColor='brand.orange'
                w={'fit-content'}
              >
                {['Unstaked', 'Staked', 'Unbonded'].map((tab, index) => (
                  <Tab
                    key={index}
                    paddingX={'4px'}
                    paddingTop={'3.4px'}
                    paddingBottom={'2.2px'}
                    fontSize={'24px'}
                    lineHeight={'25px'}
                    color='brand.yellow'
                    fontWeight='normal'
                    _selected={{
                      borderRadius: '9px',
                      backgroundColor: 'brand.yellow',
                      color: 'brand.orange',
                      fontWeight: 'normal !important'
                    }}
                    _hover={{ fontWeight: 'semibold' }}
                  >
                    {tab}
                  </Tab>
                ))}
              </TabList>
              <TabPanels marginTop={'2rem !important'}>
                <TabPanel padding={'0'}>
                  <SimpleGrid
                    spacingX={'16px'}
                    spacingY={'24px'}
                    columns={isDesktop ? 4 : 2}
                  >
                    {shownWalletNfts.map((nft, index) => (
                      <Container
                        key={index}
                        maxW={isDesktop ? '250px' : '180px'}
                        padding={'0 !important'}
                        cursor={'pointer'}
                        onClick={() => {
                          if (selectedWalletNfts.includes(nft.identifier)) {
                            setSelectedWalletNfts(
                              selectedWalletNfts.filter(
                                (nftId) => nftId !== nft.identifier
                              )
                            );
                          } else {
                            setSelectedWalletNfts([
                              ...selectedWalletNfts,
                              nft.identifier
                            ]);
                          }
                        }}
                      >
                        <Box
                          w={'full'}
                          borderRadius={'21px 21px 0px 0px'}
                          position={'relative'}
                          opacity={
                            selectedWalletNfts.includes(nft.identifier)
                              ? '1'
                              : '0.8'
                          }
                        >
                          <Box
                            position={'absolute'}
                            top={'12px'}
                            left={'12px'}
                            border={'1.2px solid'}
                            borderColor={'brand.orange'}
                            boxSize={'15px'}
                            borderRadius={'2px'}
                          />
                          {selectedWalletNfts.includes(nft.identifier) && (
                            <Box
                              position={'absolute'}
                              top={'9px'}
                              left={'10px'}
                            >
                              <Checkmark />
                            </Box>
                          )}
                          {markedCows.includes(nft.nonce) && (
                            <Box
                              position={'absolute'}
                              top={'9px'}
                              right={'10px'}
                              color={'brand.yellow'}
                              backgroundColor={'brand.orange'}
                              borderColor={'brand.orange'}
                              fontFamily={'Fredoka'}
                              padding={'2px'}
                              fontWeight={600}
                              borderRadius={'4px'}
                            >
                              <Text
                                align={'center'}
                                marginBottom={'0 !important'}
                                lineHeight={'12px'}
                                fontSize={'10px'}
                              >
                                UPGRADED
                              </Text>
                            </Box>
                          )}
                          <Image
                            borderRadius={'21px 21px 0px 0px'}
                            src={`${api}/nfts/${nft.identifier}/thumbnail`}
                            border={'3px solid'}
                            borderColor={'brand.orange'}
                          ></Image>
                          <Box
                            backgroundColor={'brand.orange'}
                            borderWidth={'0px 3px 3px 0px'}
                            borderColor={'brand.orange'}
                            borderRadius={'0px 0px 21px 21px'}
                            borderStyle={'solid'}
                            fontSize={'14px'}
                            color={'brand.yellow'}
                            fontFamily={'Fredoka'}
                            lineHeight={'20px'}
                            fontWeight={600}
                            display={'flex'}
                            paddingX={'4px'}
                            justifyContent={'space-around'}
                          >
                            <Text
                              align={'center'}
                              marginBottom={'0 !important'}
                            >
                              {
                                CowMap[
                                  nft.nonce.toString() as keyof typeof CowMap
                                ]
                              }
                            </Text>
                            <Text
                              align={'center'}
                              marginBottom={'0 !important'}
                            >
                              R:{' '}
                              {
                                NonceRanks[
                                  nft.nonce.toString() as keyof typeof NonceRanks
                                ]
                              }
                            </Text>
                          </Box>
                        </Box>
                      </Container>
                    ))}
                  </SimpleGrid>
                  {shownWalletNfts.length < walletNfts.length && (
                    <HStack marginY={'20px'} justifyContent={'center'}>
                      <Box>
                        <Button
                          className='navbar-button'
                          color={'brand.yellow'}
                          backgroundColor={'brand.orange'}
                          fontSize={'22px'}
                          margin={'0 !important'}
                          minW={'120px'}
                          padding={'17.5px 8px'}
                          borderRadius={'6px'}
                          onClick={() => {
                            setShownWalletNfts((prev) => {
                              return prev.concat(
                                walletNfts.slice(
                                  prev.length,
                                  prev.length + pageSize
                                )
                              );
                            });
                          }}
                        >
                          Load more
                        </Button>
                      </Box>
                    </HStack>
                  )}
                  <HStack
                    marginTop={'16px'}
                    marginBottom={'20px'}
                    justifyContent={'center'}
                  >
                    <Box>
                      <Button
                        className='navbar-button'
                        color={'brand.yellow'}
                        backgroundColor={'brand.orange'}
                        fontSize={'22px'}
                        margin={'0 !important'}
                        padding={'17.5px 8px'}
                        minW={'60px'}
                        borderRadius={'6px'}
                        isDisabled={selectedWalletNfts.length === 0}
                        onClick={sendStakeTransaction}
                      >
                        Stake
                      </Button>
                    </Box>
                  </HStack>
                </TabPanel>
                <TabPanel padding={'0'}>
                  <SimpleGrid
                    spacingX={'16px'}
                    spacingY={'24px'}
                    columns={isDesktop ? 4 : 2}
                  >
                    {shownStakedNfts.map((nft, index) => (
                      <Container
                        key={index}
                        maxW={isDesktop ? '250px' : '180px'}
                        padding={'0 !important'}
                        cursor={'pointer'}
                        onClick={() => {
                          if (selectedStakedNfts.includes(nft)) {
                            setSelectedStakedNfts(
                              selectedStakedNfts.filter(
                                (nftId) => nftId !== nft
                              )
                            );
                          } else {
                            setSelectedStakedNfts([...selectedStakedNfts, nft]);
                          }
                        }}
                      >
                        <Box
                          w={'full'}
                          borderRadius={'21px 21px 0px 0px'}
                          position={'relative'}
                          opacity={
                            selectedStakedNfts.includes(nft) ? '1' : '0.8'
                          }
                        >
                          <Box
                            position={'absolute'}
                            top={'12px'}
                            left={'12px'}
                            border={'1.2px solid'}
                            borderColor={'brand.orange'}
                            boxSize={'15px'}
                            borderRadius={'2px'}
                          />
                          {selectedStakedNfts.includes(nft) && (
                            <Box
                              position={'absolute'}
                              top={'9px'}
                              left={'10px'}
                            >
                              <Checkmark />
                            </Box>
                          )}

                          {
                            // @ts-ignore
                            markedCows.includes(nft.toNumber()) && (
                              <Box
                                position={'absolute'}
                                top={'9px'}
                                right={'10px'}
                                color={'brand.yellow'}
                                backgroundColor={'brand.orange'}
                                borderColor={'brand.orange'}
                                fontFamily={'Fredoka'}
                                padding={'2px'}
                                fontWeight={600}
                                borderRadius={'4px'}
                              >
                                <Text
                                  align={'center'}
                                  marginBottom={'0 !important'}
                                  lineHeight={'12px'}
                                  fontSize={'10px'}
                                >
                                  UPGRADED
                                </Text>
                              </Box>
                            )
                          }
                          <Image
                            borderRadius={'21px 21px 0px 0px'}
                            src={`${api}/nfts/${nftIdentifier}-${hexZero(
                              nft.toString()
                            )}/thumbnail`}
                            border={'3px solid'}
                            borderColor={'brand.orange'}
                          ></Image>
                          <Box
                            backgroundColor={'brand.orange'}
                            borderWidth={'0px 3px 3px 0px'}
                            borderColor={'brand.orange'}
                            borderRadius={'0px 0px 21px 21px'}
                            borderStyle={'solid'}
                            fontSize={'14px'}
                            color={'brand.yellow'}
                            fontFamily={'Fredoka'}
                            lineHeight={'20px'}
                            fontWeight={600}
                            display={'flex'}
                            paddingX={'4px'}
                            justifyContent={'space-around'}
                          >
                            <Text
                              align={'center'}
                              marginBottom={'0 !important'}
                            >
                              {CowMap[nft.toString() as keyof typeof CowMap]}
                            </Text>
                            <Text
                              align={'center'}
                              marginBottom={'0 !important'}
                            >
                              R:{' '}
                              {
                                NonceRanks[
                                  nft.toString() as keyof typeof NonceRanks
                                ]
                              }
                            </Text>
                          </Box>
                        </Box>
                      </Container>
                    ))}
                  </SimpleGrid>
                  {shownStakedNfts.length <
                    contractData['staked_nfts'].length && (
                    <HStack marginY={'20px'} justifyContent={'center'}>
                      <Box>
                        <Button
                          className='navbar-button'
                          color={'brand.yellow'}
                          backgroundColor={'brand.orange'}
                          fontSize={'22px'}
                          margin={'0 !important'}
                          minW={'120px'}
                          padding={'17.5px 8px'}
                          borderRadius={'6px'}
                          onClick={() => {
                            setShownStakedNfts((prev) => {
                              return prev.concat(
                                contractData['staked_nfts'].slice(
                                  prev.length,
                                  prev.length + pageSize
                                )
                              );
                            });
                          }}
                        >
                          Load more
                        </Button>
                      </Box>
                    </HStack>
                  )}
                  <HStack
                    marginTop={'16px'}
                    marginBottom={'20px'}
                    justifyContent={'center'}
                  >
                    <Box>
                      <Button
                        className='navbar-button'
                        color={'brand.yellow'}
                        backgroundColor={'brand.orange'}
                        fontSize={'22px'}
                        margin={'0 !important'}
                        padding={'17.5px 8px'}
                        minW={'60px'}
                        borderRadius={'6px'}
                        isDisabled={selectedStakedNfts.length === 0}
                        onClick={sendUnstakeTransaction}
                      >
                        Unstake
                      </Button>
                    </Box>
                  </HStack>
                </TabPanel>
                <TabPanel padding={'0'}>
                  <SimpleGrid
                    spacingX={'16px'}
                    spacingY={'24px'}
                    columns={isDesktop ? 4 : 2}
                  >
                    {shownClaimNfts.map((nft, index) => (
                      <Container
                        key={index}
                        maxW={isDesktop ? '250px' : '180px'}
                        padding={'0 !important'}
                        cursor={'pointer'}
                        onClick={() => {
                          {
                            if (selectedClaimNfts.includes(nft['nonce'])) {
                              setSelectedClaimNfts(
                                selectedClaimNfts.filter(
                                  (nftId) => nftId !== nft['nonce']
                                )
                              );
                            } else {
                              setSelectedClaimNfts([
                                ...selectedClaimNfts,
                                nft['nonce']
                              ]);
                            }
                          }
                        }}
                      >
                        <Box
                          w={'full'}
                          borderRadius={'21px 21px 0px 0px'}
                          position={'relative'}
                          opacity={
                            selectedClaimNfts.includes(nft['nonce'])
                              ? '1'
                              : '0.8'
                          }
                        >
                          <Box
                            position={'absolute'}
                            top={'12px'}
                            left={'12px'}
                            border={'1.2px solid'}
                            borderColor={'brand.orange'}
                            boxSize={'15px'}
                            borderRadius={'2px'}
                          />
                          {selectedClaimNfts.includes(nft['nonce']) && (
                            <>
                              <Box
                                position={'absolute'}
                                top={'9px'}
                                left={'10px'}
                              >
                                <Checkmark />
                              </Box>
                            </>
                          )}
                          {markedCows.includes(nft['nonce'].toNumber()) && (
                            <Box
                              position={'absolute'}
                              top={'9px'}
                              right={'10px'}
                              color={'brand.yellow'}
                              backgroundColor={'brand.orange'}
                              borderColor={'brand.orange'}
                              fontFamily={'Fredoka'}
                              padding={'2px'}
                              fontWeight={600}
                              borderRadius={'4px'}
                            >
                              <Text
                                align={'center'}
                                marginBottom={'0 !important'}
                                lineHeight={'12px'}
                                fontSize={'10px'}
                              >
                                UPGRADED
                              </Text>
                            </Box>
                          )}
                          <Image
                            borderRadius={'21px 21px 0px 0px'}
                            src={`${api}/nfts/${nftIdentifier}-${hexZero(
                              nft['nonce'].toNumber().toString()
                            )}/thumbnail`}
                            border={'3px solid'}
                            borderColor={'brand.orange'}
                          ></Image>
                          <Box
                            color={'brand.yellow'}
                            backgroundColor={'brand.orange'}
                            borderColor={'brand.orange'}
                            fontFamily={'Fredoka'}
                            fontWeight={600}
                            borderRadius={'0px 0px 21px 21px'}
                          >
                            <Box
                              display={'flex'}
                              paddingX={'4px'}
                              justifyContent={'space-around'}
                              fontSize={'14px'}
                            >
                              <Text
                                align={'center'}
                                marginBottom={'0 !important'}
                                lineHeight={'20px'}
                              >
                                {
                                  CowMap[
                                    nft['nonce']
                                      .toNumber()
                                      .toString() as keyof typeof CowMap
                                  ]
                                }
                              </Text>
                              <Text
                                align={'center'}
                                marginBottom={'0 !important'}
                                lineHeight={'20px'}
                              >
                                R:{' '}
                                {
                                  NonceRanks[
                                    nft['nonce']
                                      .toNumber()
                                      .toString() as keyof typeof NonceRanks
                                  ]
                                }
                              </Text>
                            </Box>

                            <Text
                              fontSize={'10px'}
                              align={'center'}
                              marginBottom={'0 !important'}
                              lineHeight={'15px'}
                              h={'19px'}
                            >
                              Claim on{' '}
                              {formatDate(nft['unlock_time'].toNumber())}
                            </Text>
                          </Box>
                        </Box>
                      </Container>
                    ))}
                  </SimpleGrid>
                  {shownClaimNfts.length <
                    contractData['unstaked_nfts'].length && (
                    <HStack marginY={'20px'} justifyContent={'center'}>
                      <Box>
                        <Button
                          className='navbar-button'
                          color={'brand.yellow'}
                          backgroundColor={'brand.orange'}
                          fontSize={'22px'}
                          margin={'0 !important'}
                          minW={'120px'}
                          padding={'17.5px 8px'}
                          borderRadius={'6px'}
                          onClick={() => {
                            setShownClaimNfts((prev) => {
                              return prev.concat(
                                contractData['unstaked_nfts'].slice(
                                  prev.length,
                                  prev.length + pageSize
                                )
                              );
                            });
                          }}
                        >
                          Load more
                        </Button>
                      </Box>
                    </HStack>
                  )}
                  <HStack
                    marginTop={'16px'}
                    marginBottom={'20px'}
                    justifyContent={'center'}
                  >
                    <Box>
                      <Button
                        className='navbar-button'
                        color={'brand.yellow'}
                        backgroundColor={'brand.orange'}
                        fontSize={'22px'}
                        margin={'0 8px 0 0 !important'}
                        padding={'17.5px 8px'}
                        minW={'60px'}
                        borderRadius={'6px'}
                        isDisabled={selectedClaimNfts.length === 0}
                        onClick={sendRestakeTransaction}
                      >
                        Restake
                      </Button>
                      <Button
                        className='navbar-button'
                        color={'brand.yellow'}
                        backgroundColor={'brand.orange'}
                        fontSize={'22px'}
                        margin={'0 0 0 8px !important'}
                        padding={'17.5px 8px'}
                        minW={'60px'}
                        borderRadius={'6px'}
                        isDisabled={
                          selectedClaimNfts.length === 0 ||
                          shouldClaimButtonBeDisabled()
                        }
                        onClick={sendClaimTransaction}
                      >
                        Claim
                      </Button>
                    </Box>
                  </HStack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Stack>
        </VStack>
      )}
      {!isLoggedIn && (
        <VStack
          w='full'
          justifyContent='center'
          alignItems='center'
          marginTop='5rem !important'
        >
          <Image
            src={MooveLogo}
            alt='Collection logo'
            h={'auto'}
            w={isDesktop ? '40%' : '96%'}
          />
          <Text
            as='div'
            color={'brand.black'}
            fontSize={'20px'}
            fontFamily={'Fredoka'}
            fontWeight={600}
            marginTop={'36px !important'}
          >
            Stake your cow
          </Text>
          <Text
            color={'brand.black'}
            fontSize={'20px'}
            fontFamily={'Fredoka'}
            fontWeight={600}
          >
            Start collecting $MOOVE
          </Text>
          <Button
            as={RouterLink}
            to={routeNames.unlock}
            className='navbar-button'
            color={'brand.yellow'}
            background={'brand.orange'}
            fontSize={'29px'}
            px='16px'
            py='20px'
            marginTop={'4px'}
          >
            Connect wallet
          </Button>
          <Stack
            direction={isDesktop ? 'row' : 'column'}
            marginTop={isDesktop ? '100px !important' : '36px !important'}
            alignItems={'center'}
          >
            <VStack minW={'300px'}>
              <Text
                color={'brand.black'}
                fontSize={'48px'}
                fontFamily={'Fredoka'}
                fontWeight={600}
                margin={0}
              >
                {totalCows}
              </Text>
              <Text color={'brand.orange'} fontSize={'48px'} margin={0}>
                Cows Staked
              </Text>
            </VStack>
            <Box
              h={isDesktop ? '184px' : '3px'}
              w={isDesktop ? '3px' : '120px'}
              borderLeft={isDesktop ? '3px solid' : 'none'}
              borderBottom={isDesktop ? 'none' : '3px solid'}
              borderColor={'brand.orange'}
              mx={isDesktop ? '100px !important' : '0px !important'}
              my={isDesktop ? '0px !important' : '36px !important'}
            />
            <VStack minW={'300px'}>
              <HStack>
                <Text
                  color={'brand.black'}
                  fontSize={'48px'}
                  fontFamily={'Fredoka'}
                  fontWeight={600}
                  margin={0}
                >
                  {circulatingSupply.toLocaleString()}{' '}
                </Text>
                <Image
                  w={'40px'}
                  src='https://raw.githubusercontent.com/ElrondNetwork/assets/master/tokens/MOOVE-875539/logo.svg'
                />
              </HStack>
              <Text color={'brand.orange'} fontSize={'48px'} margin={0}>
                CIRCULATING SUPPLY
              </Text>
            </VStack>
          </Stack>
        </VStack>
      )}
    </Container>
  );
};

export default Gallery;
