import React from 'react';
import { Container, useBreakpointValue } from '@chakra-ui/react';
import { useGetLoginInfo } from '@multiversx/sdk-dapp/hooks/account';
import { useNavigate } from 'react-router-dom';
import UnlockCard from 'components/UnlockCard';
import { routeNames } from 'routes';

function UnlockRoute() {
  const { isLoggedIn } = useGetLoginInfo();
  const navigate = useNavigate();
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  React.useEffect(() => {
    if (isLoggedIn) {
      navigate(routeNames.home);
    }
  }, [isLoggedIn]);

  return (
    <Container maxW={'400px'} centerContent mt={isDesktop ? '40' : '10'}>
      <UnlockCard></UnlockCard>
    </Container>
  );
}

export default UnlockRoute;
