import React from 'react';
import { Button, Container, Text, VStack } from '@chakra-ui/react';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { logout } from '@multiversx/sdk-dapp/utils';

const Footer = () => {
  const { address } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);

  return (
    <Container
      as='footer'
      role='contentinfo'
      centerContent
      color='brand.black'
      fontFamily='Fredoka'
      fontWeight={400}
      fontSize={'1rem'}
      marginBottom={'1em !important'}
      marginTop={'4rem'}
    >
      <VStack spacing={0} align='center'>
        {isLoggedIn && (
          <Button
            color={'brand.yellow'}
            backgroundColor={'brand.orange'}
            fontSize={'26px'}
            className='navbar-button'
            fontFamily={'CoolDaddy'}
            marginY={2}
            onClick={() => {
              logout();
            }}
          >
            Disconnect
          </Button>
        )}
        <Text as='div' lineHeight={'19.2px'}>
          © 2024. Cow Cow
        </Text>
        <Text as='div' lineHeight={'19.2px'}>
          Cow together, vibe together.
        </Text>
      </VStack>
    </Container>
  );
};

export default Footer;
