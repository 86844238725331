import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal
} from '@multiversx/sdk-dapp/UI';
import { DappProvider } from '@multiversx/sdk-dapp/wrappers';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Layout from 'components/Layout';
import { environment } from 'config';
import PageNotFound from 'pages/PageNotFound';
import UnlockRoute from 'pages/UnlockPage';
import { routeNames } from 'routes';
import routes from 'routes';

export const theme = extendTheme({
  colors: {
    brand: {
      yellow: '#feefc4',
      orange: '#c75227',
      orangehover: '#cb5d35',
      black: '#282623'
    }
  },
  fonts: {
    title: 'CoolDaddy',
    subtitle: 'Fredoka'
  }
});

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <DappProvider
          environment={environment}
          customNetworkConfig={{
            name: 'customConfig',
            apiTimeout: 6000,
            walletConnectV2ProjectId: '2247343aab227f875c90bef2965f6a35'
          }}
          dappConfig={{
            shouldUseWebViewProvider: true
          }}
        >
          <Layout>
            <TransactionsToastList />
            <NotificationModal />
            <SignTransactionsModals className='custom-class-for-modals' />
            <Routes>
              <Route path={routeNames.unlock} element={<UnlockRoute />} />
              {routes.map((route: any, index: number) => (
                <Route
                  path={route.path}
                  key={'route-key-' + index}
                  element={<route.component />}
                />
              ))}
              <Route path='*' element={<PageNotFound />} />
            </Routes>
          </Layout>
        </DappProvider>
      </Router>
    </ChakraProvider>
  );
};

export default App;
