export const stakingContractAddress =
  'erd1qqqqqqqqqqqqqpgqqgzzsl0re9e3u0t3mhv3jwg6zu63zssd7yqs3uu9jk';

export const dAppName = 'Cow Cow';

export const gateway =
  'https://elrond-mainnet.blastapi.io/83b08b54-f633-4dd9-b99d-d0b746a0e05b';

export const api =
  'https://elrond-api.blastapi.io/83b08b54-f633-4dd9-b99d-d0b746a0e05b';

export const ipfsGateway =
  'https://coffee-dear-mongoose-513.mypinata.cloud/ipfs';

export const ipfsCid = 'QmP8XL56WtNnRvWUXHh1W8MLAjekMyY5JtMw5FC72Lf3bK';

export const pageSize = 8;

export const tokenIdentifier = 'MOOVE-875539';

export const nftIdentifier = 'COW-cd463d';

export const environment = 'mainnet';
